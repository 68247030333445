import styles from '../styles/styles.styl'
import React from 'react'
import PropTypes from 'prop-types'
import { detect } from 'detect-browser'

const parseVersion = version => {
  if (version.length > 0) {
    try {
      version = version.split('.')
      return parseInt(version[0])
    } catch (e) {
      return undefined
    }
  } else {
    return undefined
  }
}

const eapBrowserSupport = browserDetectObj => {
  switch (browserDetectObj && browserDetectObj.name) {
    case 'edge-chromium':
    case 'chrome':
    case 'vivaldi':
    case 'opera':
    case 'ios':
    case 'crios':
    case 'fxios':
    case 'safari':
      return { supported: true, message: '' }

    case 'firefox':
      const version = parseVersion(browserDetectObj.version)
      if (version >= 60) {
        return { supported: true, message: '' }
      } else {
        return {
          supported: false,
          message:
            'Only Firefox 60 and above is supported. Please update Firefox or use a Chromium browser.'
        }
      }

    case 'edge': {
      return {
        supported: false,
        message: 'EdgeHTML is not supported. Please use a Chromium browser.'
      }
    }

    case 'internet explorer':
    case 'ie':
      return {
        supported: false,
        message:
          'Internet Explorer is not supported.  Please use a Chromium browser.'
      }

    default:
      return {
        supported: false,
        message: 'Unknown browser type.  Please try Chrome.'
      }
  }
}

const UnsupportedBrowser = ({ name, version, message }) => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '20%' }}>
      <i className={`${styles.swoosh} epic-icon epic-icon-nike-logo`} />
      <br />
      <br />
      <h1 className='epic-font-marketing'>UNSUPPORTED BROWSER</h1>
      <br />
      <h4 className='epic-font-base'>
        {name}/{version} is not supported.
        <br />
        <br />
        {message.split('\n').map((m, i) => (
          <p key={i}>{m}</p>
        ))}
        <a
          style={{ color: '#3498db', textDecoration: 'underline' }}
          href='https://www.google.com/chrome/'
        >
          Chrome
        </a>
      </h4>
    </div>
  )
}

UnsupportedBrowser.propTypes = {
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default class SupportedBrowsers extends React.Component {
  browser = detect()

  checkBrowserSupport = () => {
    if (this.props.isSupported) {
      return this.props.isSupported(this.browser)
    } else {
      return eapBrowserSupport(this.browser)
    }
  }

  render() {
    const result = this.checkBrowserSupport()

    if (!this.browser) {
      console.warn('No Browser detected. If you are seeing this warning in your testing environment, see https://github.com/ariesjia/jest-useragent-mock#readme for a fix if you are experiencing an error releated to browser support.')
    }

    if (result.supported) {
      return this.props.children
    } else {
      if (this.props.component) {
        const Component = this.props.component
        return (
          <Component
            name={this.browser.name}
            version={this.browser.version}
            message={result.message}
          />
        )
      } else {
        return (
          <UnsupportedBrowser
            name={this.browser.name}
            version={this.browser.version}
            message={result.message}
          />
        )
      }
    }
  }
}

SupportedBrowsers.propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  component: PropTypes.node,
  isSupported: PropTypes.node
}
