import React from 'react'
import PropTypes from 'prop-types'

import './styles/fonts'
import styles from './styles/styles.styl'

import config from './config'
import Spinner from './spinner/spinner'
import { DOMAIN_EXPRESSIONS } from './constants'
import ConsoleFeedback from './feedback/feedback'
import SupportedBrowsers from './browser-support/browser-support'
import { ErrorMessage, AppContent, ConsoleNavigation, logger } from './deps'

class IntegratedPlatform extends React.Component {
  state = {
    noScript: false,
    hasError: false,
    hasScriptError: false, // Error state specifically for nav script onerror
    ConsoleComponent: null,
    primaryScriptAttempted: false
  }

  /********************/
  /*  Error Boundary  */
  /********************/
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    logger.warn(error)
    logger.warn(errorInfo)
    this.props.onError && this.props.onError(error, errorInfo)
  }

  /********************/
  /* Script Sourcing */
  /********************/
  componentDidMount() {
    // Inject only if either domain match or platform requests visibility
    if (this.shouldInjectNavRequestScript(this.props.showConsoleNav)) {
      window.addEventListener('ConsoleInit', this.setConsoleComponent)
      this.createScript(config.scriptSrc)
    } else {
      this.setState({ noScript: true })
    }
  }

  shouldInjectNavRequestScript = showConsoleNav => {
    // Always source in console domain && development
    const domain = window.location.origin
    const isMatch = DOMAIN_EXPRESSIONS.some(d => d.test(domain))
    if (isMatch) return true

    return !!showConsoleNav
  }

  onScriptError = err => {
    console.warn(err)
    if (!this.state.primaryScriptAttempted) {
      return this.setState(
        {
          primaryScriptAttempted: true
        },
        () => this.createScript(config.scriptSrcBackup)
      )
    }
    this.setState({ hasScriptError: true })
  }

  onScriptLoad = () => {
    window.removeEventListener('ConsoleInit', this.setConsoleComponent)
  }

  createScript = src => {
    const script = document.createElement('script')
    const head = document.querySelector('head')

    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'console-nav-script')
    script.onload = this.onScriptLoad
    script.onerror = this.onScriptError

    head.appendChild(script)
  }

  setConsoleComponent = event => {
    if (!event.detail || !event.detail.ConsoleComponent) {
      return this.createScript(config.scriptSrcBackup)
    }
    this.setState({
      ConsoleComponent: event.detail.ConsoleComponent
    })
  }

  getApplicationProps = () => {
    const {
      onLogin,
      children,
      isLoggedIn,
      useConsoleLoginScreen,
      hasAuthCheckFinished
    } = this.props

    return {
      onLogin,
      children,
      isLoggedIn,
      useConsoleLoginScreen,
      hasAuthCheckFinished
    }
  }

  getConsoleNavProps = () => {
    const { ConsoleComponent } = this.state
    const { consumer, accessToken, showConsoleNav } = this.props

    return {
      consumer,
      accessToken,
      showConsoleNav,
      ConsoleComponent
    }
  }

  /********************/
  /*      Render      */
  /********************/
  render() {
    const { name, slackURL, consumer, accessToken } = this.props

    const { hasError, noScript, hasScriptError, ConsoleComponent } = this.state

    if (!noScript && !ConsoleComponent && !hasError && !hasScriptError) {
      return <Spinner show={true} overlay={false} />
    }

    if (hasError || (hasScriptError && consumer)) {
      return <ErrorMessage name={name} slackURL={slackURL} />
    } else {
      const {
        Feedback,
        isLoggedIn,
        feedbackConfig,
        hasAuthCheckFinished
      } = this.props

      const disableNav =
        !isLoggedIn && hasAuthCheckFinished ? (
          <div className={styles.disableNavigation} />
        ) : null

      return (
        <SupportedBrowsers>
          <div className="integratedNavContainer">
            <div className={styles.navArea}>
              {(noScript || !hasScriptError) && (
                <ConsoleNavigation {...this.getConsoleNavProps()} />
              )}
              {disableNav}
            </div>
            <div className={styles.contentArea}>
              <AppContent {...this.getApplicationProps()} />
              {Feedback && !disableNav && (
                <ConsoleFeedback
                  {...feedbackConfig}
                  name={name}
                  Feedback={Feedback}
                  accessToken={accessToken}
                />
              )}
            </div>
          </div>
        </SupportedBrowsers>
      )
    }
  }
}

IntegratedPlatform.propTypes = {
  /** Application Name */
  name: PropTypes.string.isRequired,
  /** Okta Access token; NOT client_id */
  accessToken: PropTypes.string.isRequired,
  /**  Feedback Component from Epic React UI Library */
  Feedback: PropTypes.func,
  /** Bool, state of user auth. Used for disabkling navigation */
  isLoggedIn: PropTypes.bool.isRequired,
  /** Boolean of whether or not auth check is still pending */
  hasAuthCheckFinished: PropTypes.bool.isRequired,
  /** Application children, must include NavBar component from Epic React UI */
  children: PropTypes.any,
  /** http url to client or web application */
  slackURL: PropTypes.string.isRequired,
  /** Source and Show Console Navigation whether on console domain or not, defaults to false */
  showConsoleNav: PropTypes.bool,
  /** Specify whether you want to let Platform console render "logged out" state (inludes login button). Defaults to true */
  useConsoleLoginScreen: PropTypes.bool,
  /** Callback when user is logged in on console provided login page. Only necessary IF useConsoleLoginScreen is true */
  onLogin: PropTypes.func,
  /** Callback when error occurs, can be used to forward error to a reporting service */
  onError: PropTypes.func,

  feedbackConfig: PropTypes.shape({
    /** Channel desired to receive feedback. MUST BE PUBLIC (for now) */
    channel: PropTypes.string.isRequired,
    /** Email of current user */
    email: PropTypes.string,
    /** Optional function that fires when feedback drawer is opened */
    onOpen: PropTypes.func,
    /** Optional function that fires when feedback drawer is close */
    onClose: PropTypes.func,
    /** Optional function that fires when html2canvas is processing the dom to capture the screenshot 'canvas'  */
    onProcessing: PropTypes.func,
    /** Optional configuration object passed to html2canvas, the library used to capture screenshots. View html2canvas docs above for more details. */
    html2canvasConfig: PropTypes.object,
    /** Optional function that fires when process is completed (after notification) */
    onScreenshotActivate: PropTypes.func,
    /** Function that fires when the onSend is successful. */
    onSendSuccess: PropTypes.func,
    /** Function that fires when the onSend produces an error. */
    onSendFailure: PropTypes.func,
    /** HTML attributes that will be applied to the Feedback Drawer */
    attributes: PropTypes.object,
    /** specifies whether the screenshot should default to including screenshot */
    defaultToIncludeScreenshot: PropTypes.bool,
    /** Image shown as demonstrative placeholder for upload image */
    previewImage: PropTypes.any,
    /** className for the overlay that appears as feedback slideout appears */
    overlayClassname: PropTypes.string,
    /** className for container div of feedback component */
    className: PropTypes.string,
    /** Message displayed to user when feedback send action is UNsuccessful */
    failureMessage: PropTypes.string,
    /** Message displayed to user when feedback send action is successful */
    successMessage: PropTypes.string
  }),
  // Hidden
  consumer: PropTypes.object
}

IntegratedPlatform.defaultProps = {
  useConsoleLoginScreen: true,
  accessToken: '',
  name: ''
}

export default IntegratedPlatform
