const backupPath = 'backup/index.js'
const sciptSrc = env => `https://console-app${env}.platforms.nike.com/`
const feedbackUrl = env =>
  `https://${env}.execute-api.us-west-2.amazonaws.com/default/feedback/send`

// TEMPORARY, REMOVE WHEN DNS IS SWITCHED
const CLIENT_URL_DEV = 'https://d2htgmpr426h04.cloudfront.net'
const NAV_URL_DEV = 'https://ddwrzy4l2v065.cloudfront.net'

const CLIENT_URL_NONPROD = 'https://d3hsoy9zauqhnu.cloudfront.net'

const CLIENT_URL_NONPROD_STAGE = 'https://d3queodhc278h8.cloudfront.net'

const CLIENT_URL_STAGE = 'https://d26ll6bs692vsg.cloudfront.net'
const NAV_URL_STAGE = 'https://d37xpweua4jk7x.cloudfront.net'

const CLIENT_URL_PROD = 'https://d3mssggg0a4rvg.cloudfront.net'
const NAV_URL_PROD = 'https://d2dozk5fci77zo.cloudfront.net'

const CLIENT_ARR = [CLIENT_URL_DEV, CLIENT_URL_NONPROD, CLIENT_URL_NONPROD_STAGE, CLIENT_URL_STAGE, CLIENT_URL_PROD]
// END TEMPORARY

const dev = {
  feedbackURL: feedbackUrl('4pn2yxl6ge'),
  scriptSrc: sciptSrc('-dev'),
  scriptSrcBackup: sciptSrc('-dev') + backupPath
}

const nonprodStage = {
  feedbackURL: feedbackUrl('yhhxq032a2'),
  scriptSrc: sciptSrc(''),
  scriptSrcBackup: sciptSrc('') + backupPath
}

const nonprod = {
  feedbackURL: feedbackUrl('cmvlzjpfv1'),
  scriptSrc: sciptSrc(''),
  scriptSrcBackup: sciptSrc('') + backupPath
}

const prodStage = {
  feedbackURL: feedbackUrl('3g5p90c5ef'),
  scriptSrc: sciptSrc('-stage'),
  scriptSrcBackup: sciptSrc('-stage') + backupPath
}

const prod = {
  feedbackURL: feedbackUrl('yubcvts10b'),
  scriptSrc: sciptSrc(''),
  scriptSrcBackup: sciptSrc('') + backupPath
}

const devTransition = {
  feedbackURL: feedbackUrl('4pn2yxl6ge'),
  scriptSrc: NAV_URL_DEV,
  scriptSrcBackup: `${NAV_URL_DEV}/${backupPath}`
}

const nonprodStageTransition = {
  feedbackURL: feedbackUrl('yhhxq032a2'),
  scriptSrc: NAV_URL_DEV,
  scriptSrcBackup: `${NAV_URL_DEV}/${backupPath}`
}

const nonprodTransition = {
  feedbackURL: feedbackUrl('cmvlzjpfv1'),
  scriptSrc: NAV_URL_DEV,
  scriptSrcBackup: `${NAV_URL_DEV}/${backupPath}`
}

const prodStageTransition = {
  feedbackURL: feedbackUrl('3g5p90c5ef'),
  scriptSrc: NAV_URL_STAGE,
  scriptSrcBackup: `${NAV_URL_STAGE}/${backupPath}`
}

const prodTransition = {
  feedbackURL: feedbackUrl('yubcvts10b'),
  scriptSrc: NAV_URL_PROD,
  scriptSrcBackup: `${NAV_URL_PROD}/${backupPath}`
}

let config = dev

const locationHasEnvURL = env =>
  window.location.origin.includes(`//console${env}.platforms.nike.com`)

// TEMPORARY
const locationHasEnvCFURL = env =>
  window.location.origin.includes(`//${env}.cloudfront.net`)

if (CLIENT_ARR.includes(window.location.origin)) {
  if (locationHasEnvCFURL('d3mssggg0a4rvg')) {
    config = prodTransition
  } else if (locationHasEnvCFURL('d26ll6bs692vsg')) {
    config = prodStageTransition
  } else if (locationHasEnvCFURL('d3hsoy9zauqhnu')) {
    config = nonprodTransition
  } else if (locationHasEnvCFURL('d3queodhc278h8')) {
    config = nonprodStageTransition
  } else if (locationHasEnvCFURL('d2htgmpr426h04')) {
    config = devTransition
  } else if (window.location.origin.includes('localhost')) {
    config = devTransition
  } else {
    config = prodTransition
  }
} else {
  if (locationHasEnvURL('')) {
    config = prod
  } else if (locationHasEnvURL('-stage')) {
    config = prodStage
  } else if (locationHasEnvURL('-nonprod')) {
    config = nonprod
  } else if (locationHasEnvURL('-nonprod-stage')) {
    config = nonprodStage
  } else if (locationHasEnvURL('-dev')) {
    config = dev
  } else if (window.location.origin.includes('localhost')) {
    config = dev
  } else {
    config = prod
  }
}

export default config
