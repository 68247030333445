import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles/styles.styl'
import { DOMAIN_EXPRESSIONS } from './constants'

const ErrorMessage = ({ slackURL, name }) => (
  <div style={{ textAlign: 'center', paddingTop: '20%' }}>
    <h1 style={{ fontSize: '30px' }} className='epic-font-marketing'>
      {'Oops...something isn\'t quite right'}
    </h1>
    <br />
    <h4 style={{ fontSize: '18px' }} className='epic-font-base'>
      An issue occurred with the platform, please refresh the page. If the error
      happens again, please contact
      {slackURL ? (
        <a
          style={{ color: '#3498db', textDecoration: 'underline' }}
          href={slackURL}
        >{` #${name}`}</a>
      ) : (
        ` ${name} team`
      )}
      .
    </h4>
    <i
      style={{
        fontSize: '30pt',
        color: '#FA5400',
        margin: '20px',
        display: 'block'
      }}
      className='epic-icon epic-icon-nike-logo'
    />
  </div>
)

ErrorMessage.propTypes = {
  name: PropTypes.string,
  slackURL: PropTypes.string
}

const Login = ({ onClick }) => (
  <div className={styles.loginMessage}>
    <h1>You must be logged in to view this page</h1>
    <button onClick={onClick}>Login</button>
  </div>
)

Login.propTypes = {
  onClick: PropTypes.func
}

// Default to true if not specified for login
const Auth = ({ useConsoleLoginScreen, onLogin, children, hasAuthCheckFinished, isLoggedIn = true }) =>
  isLoggedIn ? (
    children
  ) : hasAuthCheckFinished ? (
    useConsoleLoginScreen ? <Login onClick={onLogin} /> : children
  ) : null

Auth.propTypes = {
  useConsoleLoginScreen: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  hasAuthCheckFinished: PropTypes.bool,
  onLogin: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const AppContent = ({ children, ...props }) => (
  <Auth {...props}>
    {children || null}
  </Auth>
)

AppContent.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasAuthCheckFinished: PropTypes.bool,
  onLogin: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const ConsoleNavigation = ({
  children,
  consumer,
  accessToken,
  showConsoleNav,
  ConsoleComponent
}) => {
  const domain = window.location.origin
  const isMatch = DOMAIN_EXPRESSIONS.some(d => d.test(domain))

  if (
    window.location.hostname === 'localhost' &&
    window.localStorage.consoleAbort === 'true'
  ) {
    return null
  }

  const consoleProps = { accessToken, consumer, children }

  if (isMatch && consumer) {
    const Consumer = consumer
    return (
      <Consumer>
        {context => <ConsoleComponent {...consoleProps} {...context} />}
      </Consumer>
    )
  } else if (isMatch) {
    return <ConsoleComponent {...consoleProps} />
  } else if (!isMatch && showConsoleNav) {
    return <ConsoleComponent {...consoleProps} />
  } else {
    return null
  }
}

ConsoleNavigation.propTypes = {
  consumer: PropTypes.object
}

function writeLog(action, message) {
  const logsEnabled = window.localStorage.wood
  if (logsEnabled && logsEnabled.toString() === 'true') {
    console[action](message)
  }
}

const logger = {
  warn: message => writeLog('warn', message)
}

export { logger, AppContent, ErrorMessage, ConsoleNavigation }
