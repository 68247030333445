import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import config from '../config'
import { logger } from '../deps'

class ConsoleFeedback extends React.Component {
  sendFeedbackNoImage = (message, email, channel) => {
    let headers = {
      Authorization: `Bearer ${this.props.accessToken}`
    }

    const params = {
      channel,
      text: message + '\n *Sent by* `' + email + '`'
    }

    const url = `${config.feedbackURL}?${qs.stringify(params)}`
    const { name } = this.props // platform name for slack confirmation to user

    // eslint-disable-next-line no-undef
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, name })
    }).then(this.processResponse)
  }

  sendFeedbackWithImage = (message, screenshot, email, channel) => {
    let headers = {
      Authorization: `Bearer ${this.props.accessToken}`
    }

    const params = {
      channels: channel,
      initial_comment: message + '\n *Sent by* `' + email + '`'
    }

    const url = `${config.feedbackURL}?${qs.stringify(params)}`
    const { name } = this.props // platform name for slack confirmation to user

    // eslint-disable-next-line no-undef
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ file: screenshot.split(',')[1], email, name })
    }).then(this.processResponse)
  }

  tryJsonParse = body => {
    try {
      const parsed = JSON.parse(body)
      return parsed
    } catch (error) {
      return body
    }
  }

  processResponse = async res => {
    const json = await res.json()
    const body = json && json.response
      && this.tryJsonParse(json.response.body)

    if (res.status >= 400) {
      const errorString = `${res.status} ${json.message || ''}`
      throw new Error(errorString)
    } else if (body && !!body.error) {
      throw new Error(body.error)
    }

    return json
  }

  onSendFailure = err => {
    const { onSendFailure } = this.props
    logger.warn(err)
    if (typeof onSendFailure === 'function') {
      onSendFailure(err)
    }
  }

  onSendFeedback = (message, screenshot) => {
    const { email, channel } = this.props
    return screenshot
      ? this.sendFeedbackWithImage(message, screenshot, email, channel)
      : this.sendFeedbackNoImage(message, email, channel)
  }

  render() {
    const { Feedback, ...props } = this.props
    return (
      <Feedback
        {...props}
        onSendFailure={this.onSendFailure}
        onSend={this.onSendFeedback}
      />
    )
  }
}

ConsoleFeedback.propTypes = {
  Feedback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  channel: PropTypes.string,
  email: PropTypes.string,
  accessToken: PropTypes.string,
  onSendFailure: PropTypes.func
}

export default ConsoleFeedback
